<template>
    <div>
        <img src="../assets/img/logo-dark.png">
        <p class="mt-4">La prima community italiana di Hackintosh su <a href="https://telegram.org/" target="_blank"><span class="text-primary">Telegram</span></a></p>
        <hr>
        <p>Gruppo dedicato al progetto hackintosh: macOS su hardware non apple</p>
        <p>Questo metodo può darti la possibilità di sfruttare tutto il software della casa madre in California, che viene sviluppato in maniera esclusiva per i soli “eletti” che possono permetterselo, utilizzando componenti che hai scelto.</p>
        <hr>
    </div>
</template>

<script>
export default {
    name: 'Intro',
}
</script>