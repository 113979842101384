<template>
    <div class="container-fluid flex-column" style="height:100vh">
        <div class="row-fluid" style="height:50px"></div>
        <div style="margin-top:40px" class="row-fluid p-3">
            <h1>INTRODUZIONE</h1>
        </div>
        <div class="row bg-white align-self-stretch flex-grow-1 d-flex flex-column align-items-center">
            <div class="p-5 w-100 text-center">
                <Intro/>
                <div class="mb-5 mt-2 text-left">
                    <div class="d-flex">
                        <i class="text-danger mt-1 mr-2 fas fa-exclamation-triangle"></i>
                            <p><b>Per il regolamento scrivere <a href="#">/regole</a> nel gruppo Telegram. Coloro che non leggeranno con attenzione saranno soggetti a punizioni/ban.</b></p>
                        <i class="text-danger mt-1 ml-2 fas fa-exclamation-triangle"></i>
                    </div>
                    <p id="donazioni">Per supportare il gruppo, clicca sul link:</p>
                    <h4><a href="https://paypal.hackintoshitalia.it" target="_blank">PAYPAL</a></h4>
                    <hr>
                    <div class="d-flex text-info">
                        <i class="mt-1 mr-2 fas fa-robot"></i><h4>COMANDI BOT</h4>
                    </div>
                    <p>Questo è l'elenco dei comandi che potete usare sul gruppo Telegram:</p>
                    <div class="d-flex text-info">
                        <i class="mt-1 mr-2 fas fa-hashtag"></i><p>/BIOS</p>
                    </div>
                    <div class="d-flex text-info">
                        <i class="mt-1 mr-2 fas fa-hashtag"></i><p>/CREAZIONEUSB</p>
                    </div>
                    <div class="d-flex text-info">
                        <i class="mt-1 mr-2 fas fa-hashtag"></i><p>/OFFTOPIC</p>
                    </div>
                    <div class="d-flex text-info">
                        <i class="mt-1 mr-2 fas fa-hashtag"></i><p>/POSTINSTALL</p>
                    </div>
                    <div class="d-flex text-info">
                        <i class="mt-1 mr-2 fas fa-hashtag"></i><p>/REGOLE</p>
                    </div>
                    <div class="d-flex text-info">
                        <i class="mt-1 mr-2 fas fa-hashtag"></i><p>/SITO</p>
                    </div>
                    <div class="d-flex text-info">
                        <i class="mt-1 mr-2 fas fa-hashtag"></i><p>/SOCIAL</p>
                    </div>
                    <div class="d-flex text-info">
                        <i class="mt-1 mr-2 fas fa-hashtag"></i><p>/WINDOWS</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Intro from '../components/Intro.vue'

export default {
    name: 'Introduction',
    metaInfo:{
        title: 'Hackintosh Italia',
        titleTemplate:'%s - Introduzione',
        meta:[
            {charset: 'utf-8'},
            {name: 'keywords', content:"Hackintosh, Italia, Introduzione"},
            {name: 'description', content:'Gruppo dedicato al progetto hackintosh: macOS su hardware non apple'}
        ]
    },
    components: {
        Intro,
    }
}
</script>
